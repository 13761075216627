<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('daily_login.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('daily_login.head_list') }} </v-card-title>

        <v-card-actions>
          <v-row no-gutters class="d-flex align-center justify-end">
            <v-btn v-if="canCreate" color="primary" @click="linkToAdd" style="margin-top: 1px">{{ $t('daily_login.create')
            }}</v-btn>
            <!-- <v-spacer /> -->
            <!-- <span class="px-3">ค้นหา:</span>
            <div style="width: 150px">
              <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
            </div> -->
          </v-row>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="value"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.actionDetail`]="{ item }">
              <v-btn color="primary" class="white--text text-capitalize"
                @click="$router.push(`/dailyLogin/update/${item.id}`)">
                {{ $t('detail') }}
              </v-btn>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import TextInput from '@/components/input/TextInput.vue'
import store from '@/store'

export default {
  components: {
    DatePickerInput,
    TextInput,
  },

  data() {
    return {
      date: null,
      currentMemberDetail: {},

      search: '',
      loading: false,
      canCreate: false,
      rules: [
        value => !!value || this.$t('alert_list.list1'),
        value => (!!value && /^\d+$/.test(value)) || this.$t('alert_list.list2'),
      ],
      headers: [
        {
          text: this.$t('daily_login.header1'),
          value: 'id',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('daily_login.header2'),
          value: 'title',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('daily_login.header3'),
          value: 'month',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('daily_login.header4'),
          value: 'year',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('daily_login.header5'),
          value: 'actionDetail',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      value: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    this.getPermissionsEdit()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          const result = await this.getDailyLoginData(newValue.page, newValue.itemsPerPage)
          this.value = result.data
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'Daily Login',
              url: window.location.href,
              detail: 'ระบบ Daily Login',
              admin: userSystem.name ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getDailyLoginData(page, row) {
      try {
        return await this.$store.dispatch('getDailyLogin', `page=${page}&rows=${row}`)
      } catch (e) { }
    },

    async getPermissionsEdit() {
      let psSystem = store.getters.getPermissionsEdit
      let chkEdit = psSystem.filter(el => el === 'dailyLogin')
      if (chkEdit && chkEdit?.length > 0) {
        this.canCreate = true
        this.headers = [
          ...this.headers,
          {
            text: this.$t('alert_list.list5'),
            value: 'action',
            width: '95px',
            align: 'center',
            sortable: false,
          },
        ]
      }
    },
    async searchKeyword(value) {
      this.loading = true
      let result = {}
      if (value) {
        result = await this.$store.dispatch(
          'getDailyLogin',
          `page=1&rows=${this.pagination.rowsPerPage}&search=${value}`,
        )
      } else {
        result = await this.$store.dispatch('getDailyLogin', `page=1&rows=${this.pagination.rowsPerPage}`)
      }
      this.pagination.totalItems = result.count
      this.value = result.data
      this.pagination.page = 1
      this.loading = false
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    linkToAdd() {
      this.$router.push({ name: 'daily-login-add' })
    },
    linkToEdit(id) {
      this.$router.push({ name: 'daily-login-detail', params: { id } })
    },
  },
}
</script>
